/* Generated by Font Squirrel (http://www.fontsquirrel.com) */

/*
 * Open Sans
================================================================================ */
@font-face {
    font-family: 'opensans-regular';
    src: url('fonts/opensans/OpenSans-Regular-webfont.eot');
    src: url('fonts/opensans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/opensans/OpenSans-Regular-webfont.woff') format('woff'),
         url('fonts/opensans/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('fonts/opensans/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'opensans-italic';
    src: url('fonts/opensans/OpenSans-Italic-webfont.eot');
    src: url('fonts/opensans/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/opensans/OpenSans-Italic-webfont.woff') format('woff'),
         url('fonts/opensans/OpenSans-Italic-webfont.ttf') format('truetype'),
         url('fonts/opensans/OpenSans-Italic-webfont.svg#open_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'opensans-light';
    src: url('fonts/opensans/OpenSans-Light-webfont.eot');
    src: url('fonts/opensans/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/opensans/OpenSans-Light-webfont.woff') format('woff'),
         url('fonts/opensans/OpenSans-Light-webfont.ttf') format('truetype'),
         url('fonts/opensans/OpenSans-Light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'opensans-light-italic';
    src: url('fonts/opensans/OpenSans-LightItalic-webfont.eot');
    src: url('fonts/opensans/OpenSans-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/opensans/OpenSans-LightItalic-webfont.woff') format('woff'),
         url('fonts/opensans/OpenSans-LightItalic-webfont.ttf') format('truetype'),
         url('fonts/opensans/OpenSans-LightItalic-webfont.svg#open_sanslight_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'opensans-semibold';
    src: url('fonts/opensans/OpenSans-Semibold-webfont.eot');
    src: url('fonts/opensans/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/opensans/OpenSans-Semibold-webfont.woff') format('woff'),
         url('fonts/opensans/OpenSans-Semibold-webfont.ttf') format('truetype'),
         url('fonts/opensans/OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'opensans-semibold-italic';
    src: url('fonts/opensans/OpenSans-SemiboldItalic-webfont.eot');
    src: url('fonts/opensans/OpenSans-SemiboldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/opensans/OpenSans-SemiboldItalic-webfont.woff') format('woff'),
         url('fonts/opensans/OpenSans-SemiboldItalic-webfont.ttf') format('truetype'),
         url('fonts/opensans/OpenSans-SemiboldItalic-webfont.svg#open_sanssemibold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'opensans-bold';
    src: url('fonts/opensans/OpenSans-Bold-webfont.eot');
    src: url('fonts/opensans/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/opensans/OpenSans-Bold-webfont.woff') format('woff'),
         url('fonts/opensans/OpenSans-Bold-webfont.ttf') format('truetype'),
         url('fonts/opensans/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'opensans-bold-italic';
    src: url('fonts/opensans/OpenSans-BoldItalic-webfont.eot');
    src: url('fonts/opensans/OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/opensans/OpenSans-BoldItalic-webfont.woff') format('woff'),
         url('fonts/opensans/OpenSans-BoldItalic-webfont.ttf') format('truetype'),
         url('fonts/opensans/OpenSans-BoldItalic-webfont.svg#open_sansbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'opensans-extrabold';
    src: url('fonts/opensans/OpenSans-ExtraBold-webfont.eot');
    src: url('fonts/opensans/OpenSans-ExtraBold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/opensans/OpenSans-ExtraBold-webfont.woff') format('woff'),
         url('fonts/opensans/OpenSans-ExtraBold-webfont.ttf') format('truetype'),
         url('fonts/opensans/OpenSans-ExtraBold-webfont.svg#open_sansextrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'opensans-extrabold-italic';
    src: url('fonts/opensans/OpenSans-ExtraBoldItalic-webfont.eot');
    src: url('fonts/opensans/OpenSans-ExtraBoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/opensans/OpenSans-ExtraBoldItalic-webfont.woff') format('woff'),
         url('fonts/opensans/OpenSans-ExtraBoldItalic-webfont.ttf') format('truetype'),
         url('fonts/opensans/OpenSans-ExtraBoldItalic-webfont.svg#open_sansextrabold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

/*
 * Libre Baskerville
================================================================================ */   
@font-face {
    font-family: 'librebaskerville-bold';
    src: url('fonts/librebaskerville/librebaskerville-bold-webfont.eot');
    src: url('fonts/librebaskerville/librebaskerville-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/librebaskerville/librebaskerville-bold-webfont.woff') format('woff'),
         url('fonts/librebaskerville/librebaskerville-bold-webfont.ttf') format('truetype'),
         url('fonts/librebaskerville/librebaskerville-bold-webfont.svg#libre_baskervillebold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'librebaskerville-italic';
    src: url('fonts/librebaskerville/librebaskerville-italic-webfont.eot');
    src: url('fonts/librebaskerville/librebaskerville-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/librebaskerville/librebaskerville-italic-webfont.woff') format('woff'),
         url('fonts/librebaskerville/librebaskerville-italic-webfont.ttf') format('truetype'),
         url('fonts/librebaskerville/librebaskerville-italic-webfont.svg#libre_baskervilleitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'librebaskerville-regular';
    src: url('fonts/librebaskerville/librebaskerville-regular-webfont.eot');
    src: url('fonts/librebaskerville/librebaskerville-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/librebaskerville/librebaskerville-regular-webfont.woff') format('woff'),
         url('fonts/librebaskerville/librebaskerville-regular-webfont.ttf') format('truetype'),
         url('fonts/librebaskerville/librebaskerville-regular-webfont.svg#libre_baskervilleregular') format('svg');
    font-weight: normal;
    font-style: normal;
}


/*
 * FIXED for Font-Face Chrome Rendering
================================================================================ */
@media screen and (-webkit-min-device-pixel-ratio:0) {

   @font-face {
   font-family: 'opensans-semibold';
   src: url('fonts/opensans/OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg');
   }

   @font-face {
   font-family: 'opensans-bold';
   src: url('fonts/opensans/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
   }

}