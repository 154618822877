/*
=====================================================================
*   Ceevee v1.0 Layout Stylesheet
*   url: styleshout.com
*   03-18-2014
=====================================================================

   TOC:
   a. General Styles
   b. Header Styles
   c. About Section
   d. Resume Section
   e. Portfolio Section
   f. Call To Action Section
   g. Testimonials Section
   h. Contact Section
   i. Footer

===================================================================== */

/* ------------------------------------------------------------------ */
/* a. General Styles
/* ------------------------------------------------------------------ */

body { background: #0f0f0f; }

/* ------------------------------------------------------------------ */
/* b. Header Styles
/* ------------------------------------------------------------------ */

header {
    position: relative;
    height: 100vh;
    min-height: 500px;
    width: 100%;
    background: #161415 url(../images/header-image.jpg) no-repeat center bottom;
    background-size: cover !important;
    -webkit-background-size: cover !important;
    text-align: center;
    overflow: hidden;
}

/* vertically center banner section */
header:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}
header .banner {
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    width: 85%;
    padding-bottom: 30px;
    text-align: center;
}

header .banner-text {
    width: 100%;
    background-color: #000000AA;
    padding: 20px;
    border: 2px solid #ffa50045;
    border-radius: 10px;
}
header .banner-text h1 {
    font: 90px/1.1em 'opensans-bold', sans-serif;
    color: #fff;
    letter-spacing: -2px;
    margin: 0 auto 18px auto;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, .8);
}

header .banner-text h2 {
    font: 45px/1.1em 'opensans-bold', sans-serif;
    color: #fff;
    letter-spacing: -2px;
    margin: 0 auto 18px auto;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, .8);
}

header .banner-text h3 {
    font: 18px/1.9em 'librebaskerville-regular', serif;
    color: #A8A8A8;
    margin: 0 auto;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, .5);
}
header .banner-text h3 span,
header .banner-text h3 a {
    color: #fff;
}
header .banner-text hr {
    width: 60%;
    margin: 18px auto 24px auto;
    border-color: #2F2D2E;
    border-color: rgba(150, 150, 150, .1);
}

/* header social links */
header .social {
    margin: 24px 0;
    padding: 0;
    font-size: 30px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, .8);
}
header .social li {
    display: inline-block;
    margin: 0 15px;
    padding: 0;
}
header .social li a { color: #fff; }
header .social li a:hover { color: #00af0e; }

/* scrolldown link */
header .scrolldown a, header .scrolldown Link {
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-left: -29px;
    color: #fff;
    display: block;
    height: 42px;
    width: 42px;
    font-size: 42px;
    line-height: 42px;
    color: #fff;
    border-radius: 100%;

    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}
header .scrolldown a:hover { color: #00af0e; }

/* primary navigation
--------------------------------------------------------------------- */
#nav-wrap ul, #nav-wrap li, #nav-wrap a, #nav-wrap Link {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
}

/* nav-wrap */
#nav-wrap {
    font: 12px 'opensans-bold', sans-serif;
    width: 100%;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    margin: 0 auto;
    z-index: 100;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 1;
}
.opaque { background-color: #333; }

/* hide toggle button */
#nav-wrap > a.mobile-btn { display: none; }

ul#nav {
    min-height: 48px;
    width: auto;

    /* center align the menu */
    text-align: center;
}
ul#nav li {
    position: relative;
    list-style: none;
    height: 48px;
    display: inline-block;
}

/* Links */
ul#nav li a, ul#nav li .top-link {

    /* 8px padding top + 8px padding bottom + 32px line-height = 48px */

    display: inline-block;
    padding: 8px 13px;
    line-height: 32px;
    text-decoration: none;
    text-align: left;
    color: #fff;

    -webkit-transition: color .2s ease-in-out;
    -moz-transition: color .2s ease-in-out;
    -o-transition: color .2s ease-in-out;
    -ms-transition: color .2s ease-in-out;
    transition: color .2s ease-in-out;
}

ul#nav li a:active { background-color: transparent !important; }
ul#nav li.current a, ul#nav li.current Link { color: #00af0e; }

ul#nav li .top-link.active {
    color: #00af0e;
    background-color: transparent !important;
}

.top-link:hover {
    color: #00af0e !important;
    cursor: pointer !important;
}

#home .profile-pic {
    position: relative;
    width: 250px;
    border-radius: 25px;
}

#nav-wrap.fadeIn {
    opacity: 1 !important;
    -webkit-transition: opacity 0.25s ease-in;
    -moz-transition: opacity 0.25s ease-in;
    -ms-transition: opacity 0.25s ease-in;
    -o-transition: opacity 0.25s ease-in;
    transition: opacity 0.25s ease-in;
}

#nav-wrap.fadeOut {
    opacity: 0 !important;
    -webkit-transition: opacity 0.25s ease-out;
    -moz-transition: opacity 0.25s ease-out;
    -ms-transition: opacity 0.25s ease-out;
    -o-transition: opacity 0.25s ease-out;
    transition: opacity 0.25s ease-out;
}

/* ------------------------------------------------------------------ */
/* c. About Section
/* ------------------------------------------------------------------ */

#about {
    background: #2B2B2B;
    padding-top: 96px;
    padding-bottom: 66px;
    overflow: hidden;
}

#about a, #about a:visited  { color: #fff; }
#about a:hover, #about a:focus { color: #00af0e; }

#about h2 {
    font: 22px/30px 'opensans-bold', sans-serif;
    color: #fff;
    margin-bottom: 12px;
}
#about p, #about td {
    line-height: 30px;
    color: #f5f5f5;
}
#about .profile-pic {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 100%;
}

#about .contact-details { width: 41.66667%; }
#about .download {
    width: 58.33333%;
    padding-top: 6px;
}
#about .main-col { padding-right: 5%; }
#about .download .button {
    margin-top: 6px;
    background: #444;
}
#about .download .button:hover {
    background: #fff;
    color: #2B2B2B;
}
#about .download .button i {
    margin-right: 15px;
    font-size: 20px;
}


/* ------------------------------------------------------------------ */
/* d. Resume Section
/* ------------------------------------------------------------------ */

#resume {
    background: #fff;
    padding-top: 90px;
    padding-bottom: 72px;
    overflow: hidden;
}

#resume a, #resume a:visited  { color: #00af0e; }
#resume a:hover, #resume a:focus { color: #313131; }

#resume h1 {
    font: 18px/24px 'opensans-bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
}
#resume h1 span {
    border-bottom: 3px solid #00af0e;
    padding-bottom: 6px;
}
#resume h2 {
    font: 12px/18px 'opensans-bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
}
#resume h2 span {
    border-bottom: 3px solid #00af0e;
    padding-bottom: 6px;
}

#resume h3 {
    font: 25px/30px 'opensans-bold', sans-serif;
}

#resume .header-col { padding-top: 9px; }
#resume .main-col { padding-right: 10%; }

.education, .work {
    margin-bottom: 48px;
    padding-bottom: 24px;
    border-bottom: 1px solid #E8E8E8;
}
#resume .info {
    font: 16px/24px 'librebaskerville-italic', serif;
    color: #6E7881;
    margin-bottom: 18px;
    margin-top: 9px;
}
#resume .info span {
    margin-right: 5px;
    margin-left: 5px;
}
#resume .date {
    font: 15px/24px 'opensans-regular', sans-serif;
    margin-top: 6px;
}

/* ------------------------------------------------------------------ */
/* e. Portfolio Section
/* ------------------------------------------------------------------ */

#portfolio {
    background: #ebeeee;
    padding-top: 90px;
    padding-bottom: 60px;
}
#portfolio h1 {
    font: 15px/24px 'opensans-semibold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 48px;
    color: #95A3A3;
}

/* Portfolio Content */
#portfolio-wrapper .columns { margin-bottom: 36px; }
.portfolio-item .item-wrap {
    background: #fff;
    overflow: hidden;
    position: relative;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.portfolio-item .item-wrap a {
    display: block;
    cursor: pointer;
}

/* overlay */
.portfolio-item .item-wrap .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    opacity: 0;
    -moz-opacity: 0;
    filter:alpha(opacity=0);

    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;

    background: url(../images/overlay-bg.png) repeat;
}
.portfolio-item .item-wrap .link-icon {
    display: block;
    color: #fff;
    height: 30px;
    width: 30px;
    font-size: 18px;
    line-height: 30px;
    text-align: center;

    opacity: 0;
    -moz-opacity: 0;
    filter:alpha(opacity=0);

    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;

    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
}
.portfolio-item .item-wrap img {
    vertical-align: bottom;
}
.portfolio-item .portfolio-item-meta { padding: 18px }
.portfolio-item .portfolio-item-meta h5 {
    font: 14px/21px 'opensans-bold', sans-serif;
    color: #fff;
}
.portfolio-item .portfolio-item-meta p {
    font: 12px/18px 'opensans-light', sans-serif;
    color: #c6c7c7;
    margin-bottom: 0;
}

/* on hover */
.portfolio-item:hover .overlay {
    opacity: 1;
    -moz-opacity: 1;
    filter:alpha(opacity=100);
}
.portfolio-item:hover .link-icon {
    opacity: 1;
    -moz-opacity: 1;
    filter:alpha(opacity=100);
}

/* popup modal */
.popup-modal {
    max-width: 550px;
    background: #fff;
    position: relative;
    margin: 0 auto;
}
.popup-modal .description-box { padding: 12px 36px 18px 36px; }
.popup-modal .description-box h4 {
    font: 15px/24px 'opensans-bold', sans-serif;
    margin-bottom: 12px;
    color: #111;
}
.popup-modal .description-box p {
    font: 14px/24px 'opensans-regular', sans-serif;
    color: #A1A1A1;
    margin-bottom: 12px;
}
.popup-modal .description-box .categories {
    font: 11px/21px 'opensans-light', sans-serif;
    color: #A1A1A1;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: block;
    text-align: left;
}
.popup-modal .description-box .categories i {
    margin-right: 8px;
}
.popup-modal .link-box {
    padding: 18px 36px;
    background: #111;
    text-align: left;
}
.popup-modal .link-box a {
    color: #fff;
    font: 11px/21px 'opensans-bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    cursor: pointer;
}
.popup-modal a:hover {	color: #00CCCC; }
.popup-modal a.popup-modal-dismiss { margin-left: 24px; }


/* fadein/fadeout effect for modal popup
/* ------------------------------------------------------------------ */

/* content at start */
.mfp-fade.mfp-wrap .mfp-content .popup-modal {
    opacity: 0;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
}
/* content fadein */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content .popup-modal {
    opacity: 1;
}
/* content fadeout */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content .popup-modal {
    opacity: 0;
}

/* ------------------------------------------------------------------ */
/* f. Call To Action Section
/* ------------------------------------------------------------------ */

#call-to-action {
    background: #212121;
    padding-top: 66px;
    padding-bottom: 48px;
}
#call-to-action h1 {
    font: 18px/24px 'opensans-bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;
}
#call-to-action h1 span { display: none; }
#call-to-action .header-col h1:before {
    font-family: 'FontAwesome';
    content: "\f0ac";
    padding-right: 10px;
    font-size: 72px;
    line-height: 72px;
    text-align: left;
    float: left;
    color: #fff;
}
#call-to-action .action {
    margin-top: 12px;
}
#call-to-action h2 {
    font: 28px/36px 'opensans-bold', sans-serif;
    color: #EBEEEE;
    margin-bottom: 6px;
}
#call-to-action h2 a {
    color: inherit;
}
#call-to-action p {
    color: #636363;
    font-size: 17px;
}
/*#
call-to-action .button {
	color:#fff;
   background: #0D0D0D;
}
*/
#call-to-action .button:hover,
#call-to-action .button:active {
    background: #FFFFFF;
    color: #0D0D0D;
}
#call-to-action p span {
    font-family: 'opensans-semibold', sans-serif;
    color: #D8D8D8;
}

/* ------------------------------------------------------------------
/* g. Testimonials
/* ------------------------------------------------------------------ */

#testimonials {
    background: #1F1F1F url(../images/testimonials-bg.jpg) no-repeat center center;
    background-size: cover !important;
    -webkit-background-size: cover !important;
    background-attachment: fixed;

    position: relative;
    min-height: 200px;
    width: 100%;
    overflow: hidden;
}
#testimonials .text-container {
    padding-top: 96px;
    padding-bottom: 66px;
}
#testimonials h1 {
    font: 18px/24px 'opensans-bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;
}
#testimonials h1 span { display: none; }
#testimonials .header-col { padding-top: 9px; }
#testimonials .header-col h1:before {
    font-family: 'FontAwesome';
    content: "\f10d";
    padding-right: 10px;
    font-size: 72px;
    line-height: 72px;
    text-align: left;
    float: left;
    color: #fff;
}

/*	Blockquotes */
#testimonials blockquote {
    margin: 0 0px 30px 0px;
    padding-left: 0;
    position: relative;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
}
#testimonials blockquote:before { content: none; }
#testimonials blockquote p {
    font-family: 'librebaskerville-italic', serif;
    padding: 0;
    font-size: 24px;
    line-height: 48px;
    color: #fff
}
#testimonials blockquote cite {
    display: block;
    font-size: 12px;
    font-style: normal;
    line-height: 18px;
    color: #fff;
}
#testimonials blockquote cite:before { content: "\2014 \0020"; }
#testimonials blockquote cite a,
#testimonials blockquote cite a:visited { color: #8B9798; border: none }


#skills h2 {
    font: 18px/24px 'opensans-bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;
}

/* Flex Slider
/* ------------------------------------------------------------------ */

/* Reset */
.flexslider a:active,
.flexslider a:focus  { outline: none; }
.slides,
.flex-control-nav,
.flex-direction-nav { margin: 0; padding: 0; list-style: none; }
.slides li { margin: 0; padding: 0;}

/* Necessary Styles */
.flexslider {
    position: relative;
    zoom: 1;
    margin: 0;
    padding: 0;
}
.flexslider .slides { zoom: 1; }
.flexslider .slides > li { position: relative; }

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides > li { display: none; -webkit-backface-visibility: hidden; }
/* Suggested container for slide animation setups. Can replace this with your own */
.flex-container { zoom: 1; position: relative; }

/* Clearfix for .slides */
.slides:before,
.slides:after {
    content: " ";
    display: table;
}
.slides:after {
    clear: both;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child { display: block; }

/* Slider Styles */
.slides { zoom: 1; }
.slides > li {
    /*margin-right: 5px; */
    overflow: hidden;
}

/* Control Nav */
.flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: -20px;
    text-align: left;
}
.flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline;
}
.flex-control-paging li a {
    width: 12px;
    height: 12px;
    display: block;
    background: #ddd;
    background: rgba(255, 255, 255, .3);
    cursor: pointer;
    text-indent: -9999px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    box-shadow: inset 0 0 3px rgba(255, 255, 255, .3);
}
.flex-control-paging li a:hover {
    background: #CCC;
    background: rgba(255, 255, 255, .7);
}
.flex-control-paging li a.flex-active {
    background: #fff;
    background: rgba(255, 255, 255, .9);
    cursor: default;
}

/* ------------------------------------------------------------------ */
/* h. Contact Section
/* ------------------------------------------------------------------ */

#contact {
    background: #191919;
    padding-top: 96px;
    padding-bottom: 102px;
    color: #636363;
}
#contact .section-head { margin-bottom: 42px; }

#contact a, #contact a:visited  { color: #009616; }
#contact a:hover, #contact a:focus { color: #fff; }

#contact h1 {
    font: 18px/24px 'opensans-bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #EBEEEE;
    margin-bottom: 6px;
}
#contact h1 span { display: none; }
#contact h1:before {
    font-family: 'FontAwesome';
    content: "\f0e0";
    padding-right: 10px;
    font-size: 72px;
    line-height: 72px;
    text-align: left;
    float: left;
    color: #ebeeee;
}

#contact h4 {
    font: 16px/24px 'opensans-bold', sans-serif;
    color: #EBEEEE;
    margin-bottom: 6px;
}
#contact p.lead {
    font: 18px/36px 'opensans-light', sans-serif;
    padding-right: 3%;
}
#contact .header-col { padding-top: 6px; }


/* contact form */
#contact form { margin-bottom: 30px; }
#contact label {
    font: 15px/24px 'opensans-bold', sans-serif;
    margin: 12px 0;
    color: #EBEEEE;
    display: inline-block;
    float: left;
    width: 26%;
}
#contact input,
#contact textarea,
#contact select {
    padding: 18px 20px;
    color: #eee;
    background: #373233;
    margin-bottom: 42px;
    border: 0;
    outline: none;
    font-size: 15px;
    line-height: 24px;
    width: 100%;
}
#contact input:focus,
#contact textarea:focus,
#contact select:focus {
    color: #fff;
    background-color: rgba(142, 190, 103, 0.4);
}
#contact button.submit {
    text-transform: uppercase;
    letter-spacing: 3px;
    color:#fff;
    background: #0D0D0D;
    border: none;
    cursor: pointer;
    height: auto;
    display: inline-block;
    border-radius: 3px;
    margin-left: 26%;
}
#contact button.submit:hover {
    color: #0D0D0D;
    background: #fff;
}
#contact span.required {
    color: rgba(142, 190, 103, 0.58);
    font-size: 13px;
}
#message-warning, #message-success {
    display: none;
    background: #0F0F0F;
    padding: 24px 24px;
    margin-bottom: 36px;
    width: 65%;
    margin-left: 26%;
}
#message-warning { color: #D72828; }
#message-success { color: #11ABB0; }

#message-warning i,
#message-success i {
    margin-right: 10px;
}
#image-loader {
    display: none;
    position: relative;
    left: 18px;
    top: 17px;
}


/* Twitter Feed */
#twitter {
    margin-top: 12px;
    padding: 0;
}
#twitter li {
    margin: 6px 0px 12px 0;
    line-height: 30px;
}
#twitter li span {
    display: block;
}
#twitter li b a {
    font: 13px/36px 'opensans-regular', Sans-serif;
    color: #474747 !important;
    border: none;
}


/* ------------------------------------------------------------------ */
/* i. Footer
/* ------------------------------------------------------------------ */

footer {
    padding-top: 48px;
    margin-bottom: 48px;
    color: #303030;
    font-size: 14px;
    text-align: center;
    position: relative;
}

footer a, footer a:visited { color: #525252; }
footer a:hover, footer a:focus { color: #fff; }

/* copyright */
footer .copyright {
    margin: 0;
    padding: 0;
}
footer .copyright li {
    display: inline-block;
    margin: 0;
    padding: 0;
    line-height: 24px;
}
.ie footer .copyright li {
    display: inline;
}
footer .copyright li:before {
    content: "\2022";
    padding-left: 10px;
    padding-right: 10px;
    color: #1a531e;
}
footer .copyright  li:first-child:before {
    display: none;
}

/* social links */
footer .social {
    margin: 18px 0 30px 0;
    padding: 0;
    font-size: 30px;
}
footer .social li {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-left: 42px;
    color: #F06000;
}

footer .social li:first-child { margin-left: 0; }

/* Go To Top Button */
#go-top {
    position: absolute;
    top: -24px;
    left: 50%;
    margin-left: -30px;
}
#go-top a {
    text-decoration: none;
    border: 0 none;
    display: block;
    width: 60px;
    height: 60px;
    background-color: #525252;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    color: #fff;
    font-size: 21px;
    line-height: 60px;
    border-radius: 100%;
}
#go-top a:hover { background-color: #525252; }

