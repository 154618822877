$textColor: #ffffff;
$skillBgColor: #eee;
$titleBackground: #d35400;
$barBackground: #e67e22;

.imageBar {
  position:relative;
  display:block;
  width:100%;
  background:$skillBgColor;
  border-radius:3px;
  -moz-border-radius:3px;
  -webkit-border-radius:3px;
  -webkit-transition:0.4s linear;
  -moz-transition:0.4s linear;
  -ms-transition:0.4s linear;
  -o-transition:0.4s linear;
  transition:0.4s linear;
  -webkit-transition-property:width, background-color;
  -moz-transition-property:width, background-color;
  -ms-transition-property:width, background-color;
  -o-transition-property:width, background-color;
  transition-property:width, background-color;
}

.imageBar-title {
  position:absolute;
  top:0;
  left:0;
  font-weight:bold;
  font-size:13px;
  color: $textColor;
  background: $titleBackground;
  -webkit-border-top-left-radius:3px;
  -webkit-border-bottom-left-radius:4px;
  -moz-border-radius-topleft:3px;
  -moz-border-radius-bottomleft:3px;
  border-top-left-radius:3px;
  border-bottom-left-radius:3px;
  height:inherit;
}

.imageBar-title span {
  display:block;
  background:rgba(0, 0, 0, 0.1);
  padding:0 10px;
  height:inherit;
  line-height:inherit;
  -webkit-border-top-left-radius:3px;
  -webkit-border-bottom-left-radius:3px;
  -moz-border-radius-topleft:3px;
  -moz-border-radius-bottomleft:3px;
  border-top-left-radius:3px;
  border-bottom-left-radius:3px;
}

.imageBar-bar {
  height:inherit;
  position:absolute;
  background:$barBackground;
  border-radius:3px;
  -moz-border-radius:3px;
  -webkit-border-radius:3px;
}

.imageBar-percent {
  position:absolute;
  right:10px;
  top:0;
  font-size:11px;
  line-height:inherit;
  color:$textColor;
  color:rgba(0, 0, 0, 0.4);
}

.collapsed {
  width: 0% !important;
}

.icon span {
  vertical-align: baseline !important;
}
